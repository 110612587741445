<template>
  <div>
    <v-container
      fluid
      class="down-top-padding"
    >
      <v-row>
        <v-col
          sm="12"
          lg="12"
          md="12"
        >
          <v-card class="mb-5">
            <v-card-text class="pa-5">
              <v-toolbar flat>
                <v-toolbar-title>Club Product Details</v-toolbar-title>
                <v-spacer />
                <template v-if="loggedInUser.role == 'Administrator' && selectedClubProduct.isActive == true">
                  <v-btn
                    text
                    @click="deleteClubProduct"
                  >
                    DELETE CLUB PRODUCT
                  </v-btn>
                </template>
                <template v-if="loggedInUser.role == 'Administrator' && selectedClubProduct.isActive == false">
                  <v-btn
                    text
                    @click="restoreClubProduct"
                  >
                    RESTORE CLUB PRODUCT
                  </v-btn>
                </template>
              </v-toolbar>
              <v-divider />
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Record ID
                      </h3>
                      <br>
                      <p>{{ selectedClubProduct._id || "Not Provided" }}</p>
                      <v-divider />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Club Product Name
                      </h3>
                      <br>
                      <p>{{ selectedClubProduct.name || "Not Provided" }}</p>
                      <v-divider />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Description
                      </h3>
                      <br>
                      <p>{{ selectedClubProduct.description || "Not Provided" }}</p>
                      <v-divider />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Club Price
                      </h3>
                      <br>
                      <p v-if="selectedClubProduct.club_price">
                        {{ selectedClubProduct.club_price }}
                      </p>
                      <p v-else>
                        Not Provided
                      </p>
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          sm="12"
          lg="12"
          md="12"
        >
          <v-card class="mb-5">
            <v-card-text class="pa-5">
              <v-toolbar flat>
                <v-toolbar-title>Product Entry Details</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-divider />
              <v-data-table
                :items="selectedClubProduct.product_entry"
                :headers="productEntryHeaders"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'ClubProduct',
    data () {
      return {
        productEntryHeaders: [
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Prefix',
            value: 'prefix',
          },
          {
            text: 'Type',
            value: 'type',
          },
        ],
      }
    },
    created () {
      this.$store.dispatch('viewClubProduct', this.$route.params.id)
    },
    computed: {
      ...mapGetters(['selectedClubProduct', 'loggedInUser']),
    },
    methods: {
      back () {
        this.$router.back()
      },
      deleteClubProduct () {
        this.$store.dispatch('deleteClubProduct', this.$route.params.id)
        this.$router.push('/admin/club-products')
      },
      restoreClubProduct () {
        this.$store.dispatch('restoreClubProduct', this.$route.params.id)
        this.$router.go()
      },
    },
  }
</script>
